import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const Boxes2 = () => {
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Active User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.ActiveUser}</div>
            <UsergroupDeleteOutlined />
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Inactive User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.InactiveUser}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=0")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Active Vendor</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.ActiveVendor}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=1")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Inactive Vendor
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.InactiveVendor}
            </div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/users?page=1&status=2")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">br</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.brUser}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/users?page=1&status=3")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600"> ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.ctUser}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/users?page=1&status=4")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.mqUser}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/users?page=1&status=5")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.duqeUser}</div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.companyBusinessWithGst}
            </div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-ct")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.gpgct}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-mq")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Gpg mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.gpgmq}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-duqe")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.gpgduqe}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
        //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total admin commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.adminWinCommission}</div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes2;
